//export NODE_OPTIONS=--openssl-legacy-provider
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logos/fulllogo_nobuffer.jpg';
// import logo_with_text from './logos/logo-with-text.jpeg';
// import small_logo from './logos/icononly.png';
import './App.css';
import Footer from './components/FooterNew.js';
import SliderComponent from './components/Slider.js';
import About from './components/About.js';
import GetInvolved from './components/GetInvolved.js';
import ContactUs from './components/ContactUs.js';

import ronaldProfile from '../src/assets/img/ronald.jpeg';
import woodrowProfile from '../src/assets/img/woodrow.jpeg';
import franklinProfile from '../src/assets/img/frank.jpeg';
import bushProfile from '../src/assets/img/bush.jpeg';
import bidenProfile from '../src/assets/img/binden.jpeg';
import harryProfile from '../src/assets/img/harry.jpeg';
import billProfile from '../src/assets/img/bill.jpeg';
import council from './assets/img/cropped_council.png';
// import SlideShow from './components/SlideShow.js';
import ThreeBarIcon from './components/ThreeBarIcon.js';
const paypal_url = 'https://www.sandbox.paypal.com/checkoutnow?atomic-event-state=eyJkb21haW4iOiJzZGtfcGF5cGFsX3Y1IiwiZXZlbnRzIjpbXSwiaW50ZW50IjoiY2xpY2tfcGF5bWVudF9idXR0b24iLCJpbnRlbnRUeXBlIjoiY2xpY2siLCJpbnRlcmFjdGlvblN0YXJ0VGltZSI6NzYzMjcuNjk5OTk5OTk1NTMsInRpbWVTdGFtcCI6NzYzMjgsInRpbWVPcmlnaW4iOjE3MjgyNDk1NjMyNzkuNSwidGFzayI6InNlbGVjdF9vbmVfdGltZV9jaGVja291dCIsImZsb3ciOiJvbmUtdGltZS1jaGVja291dCIsInVpU3RhdGUiOiJ3YWl0aW5nIiwicGF0aCI6Ii9zbWFydC9idXR0b25zIiwidmlld05hbWUiOiJwYXlwYWwtc2RrIn0%3D&sessionID=uid_a1cd26d1ec_mje6mtc6mjm&buttonSessionID=uid_30de077d01_mje6mtk6mjm&stickinessID=uid_adc320e4e8_mje6mtc6mjq&smokeHash=&sign_out_user=false&fundingSource=paypal&buyerCountry=US&locale.x=en_US&commit=true&client-metadata-id=uid_a1cd26d1ec_mje6mtc6mjm&enableFunding.0=venmo&standaloneFundingSource=paypal&branded=true&token=0WV037525J871204J&clientID=AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R&env=sandbox&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5zYW5kYm94LnBheXBhbC5jb20vc2RrL2pzP2NsaWVudC1pZD10ZXN0Jm1lcmNoYW50LWlkPVlRWkNIVEdIVUs1UDgmY29tcG9uZW50cz1idXR0b25zLGZ1bmRpbmctZWxpZ2liaWxpdHkmY3VycmVuY3k9VVNEJmxvY2FsZT1lbl9VUyZlbmFibGUtZnVuZGluZz12ZW5tbyIsImF0dHJzIjp7ImRhdGEtY3NwLW5vbmNlIjoiV20rbG9xTmxvQnNvNFU3ZG9uSDFhc1ZOeXUzbExqVXNITDl2aE5lM2g0aUpKbTNOIiwiZGF0YS1zZGstaW50ZWdyYXRpb24tc291cmNlIjoicmVhY3QtcGF5cGFsLWpzIiwiZGF0YS11aWQiOiJ1aWRfaGRwc2lubmx6YXF0aGNzd2doeGhoaGJobHFlenBhIn19&country.x=US&xcomponent=1&version=5.0.460';

const cards = [
  {
    image: bushProfile,
    title: "George Bush Jr.",
    biography: "The United States must defend liberty and justice because these principles are right and true for all people everywhere. No nation owns these aspirations, and no nation is exempt from them. Fathers and mothers in all societies want their children to be educated and to live free from poverty and violence. No people on earth yearn to be oppressed, aspire to servitude, or eagerly await the midnight knock of the secret police.",
    designation: "US President"
  },
  {
    image: ronaldProfile,
    title: "Ronald Reagan",
    biography: "The United States must be a beacon for democracy. Unfortunately, many in the world are prevented from seeing our beacon. For many more, it has been distorted; and still others, who are able to see it and are inspired by it, need help in the form of practical assistance.",
    designation: "US President"
  },
  {
    image: harryProfile,
    title: "Harry S. Truman",
    biography: "Democracy alone can supply the vitalizing force to stir the peoples of the world into triumphant action, not only against their human oppressors, but also against their ancient enemies—hunger, misery, and despair.",
    designation: "US President"
  },
  {
    image: franklinProfile,
    title: "Franklin D. Roosevelt",
    biography: "We have learned, that we cannot live alone at peace. We have learned that our own well being is dependent on the well being of other nations far away. We have learned to be citizens of the world, members of the human community.",
    designation: "US President"
  },
  {
    image: billProfile,
    title: "Bill Clinton",
    biography: "[F]ree elections [alone] are not enough. Genuine, lasting democracy also requires respect for human rights, including the right to political dissent; freedom of religion and belief; an independent media capable of engaging an informed citizenry; a robust civil society; the rule of law and an independent judiciary; open and competitive economic structures; mechanisms to safeguard minorities from oppressive rule by the majority; full respect for women’s and workers’ rights; and civilian control of the military.",
    designation: "US President"
  },
  {
    image: bidenProfile,
    title: "Joe Biden",
    biography: "I firmly believe that democracy holds the key to freedom, prosperity, peace, and dignity. We must now demonstrate — with a clarity that dispels any doubt — that democracy can still deliver for our people and for people around the world. We must prove that our model isn’t a relic of history; it’s the single best way to realize the promise of our future. And, if we work together with our democratic partners, with strength and confidence, we will meet every challenge and outpace every challenger.",
    designation: "US President"
  },
  {
    image: woodrowProfile,
    title: "Woodrow Wilson",
    biography: "No peace can last, or ought to last, which does not recognize and accept the principle that governments derive all their just powers from the consent of the governed, and that no right anywhere exists to hand peoples about from sovereignty to sovereignty as if they were property.",
    designation: "US President"
  }
];

function App() {
  return (
    <Router>
      <div>
        <div id='container'>
        
        <div id='header'>
          <div id="header-top">
            {/* left div */}
              <div id="header-top-logo">
                <a href="/">
                  <img src={logo} alt="logo" className="logo-image" />
                </a>
              </div>
              <div id="header-top-slogan"> 
        
                <div class="charter-paper">
                <h1>Conceiving a world governed through laws</h1>
                </div>
                {/*display: none by defult and it is loaded in mobile view */}
              
              <div id="threebars">
                <ThreeBarIcon/>
              </div>
              </div>
            </div>

            <div id="header-navbar">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              {/* <Link to="/visionaries">Visionaries</Link> */}
              <Link to="/">Forth Coming</Link>
              <Link to="/contactus">Contact Us</Link>
              <a href={paypal_url} className="split" target="_blank" rel="noopener noreferrer">
                Support Us
                </a>
            </div>
        </div>

        <div id="body-div"> 
          <Routes>
            <Route path="/" element={
              <div id="home">
                <div id='background-img'>
                <img src={council} alt="council_image" style={{width: '100%'}}/>

                <div class='text-over-image'>
                <p>
                The Constitutional Order Institute aims to revolutionize theory and practice of governance by adopting medical approach to peace. Indeed, for the institute, state qualifies as if it were a living organism prone to conflict (disease) and curable with constitution (medicine). Medical professionals know it well that the road to health passes through the study of “cell”. The institute—building on the founder’s vision—likewise believes that the road to peace passes through the study of “market”. Not surprisingly, the Institute derives laws—specifically constitution and international law—largely to help citizens and statesmen secure peace and liberty worldwide.
                 </p>
                </div>
                </div>

                
                <br></br>

               
                <div class='rest-of-body-without-top-image'>
                <h1><span> Why COI?</span></h1>
                <div className="objectives-list">
                <p>
                Conflict and constitution are two sides of the same coin—call it the international system. Where the former equals disease, the latter equals medicine. However, rather than studying both subjects together, social scientists study them independent of each other. Consequently, they hardly grasp what state is, why democracy fails, and what it takes to keep peace. By adopting medical approach to peace, this institute analyzes conflict vis-à-vis constitution offering a general theory of law, which on the one hand strengthens democracy and on the other eradicates war.
                </p>

                </div>
                <h1><span> What Political Leaders Say</span></h1>
                <div id='slider-div'>
                <SliderComponent cards={cards} slidesToShow={3} />
                </div>
                
              <br></br>
              <br></br>
              <br></br>
                <h1><span> Our Mission</span></h1>
                <div className="objectives-list">
                  <ul>
                    <li>Redefine concepts of state, conflict, constitution and the international order.</li>
                    <li>Raise awareness and understanding of constitutional rights and responsibilities among citizens.
                    </li>
                    <li>Support policies and initiatives that align with constitutional values.
                    </li>
                    <li>Bring together diverse communities to work towards common goals grounded in constitutional principles.
                    </li>
                  </ul>
                </div>
                
                <h1><span> Our Vision</span></h1>
                <h2>We envision a world where:
                </h2>
                <div className="objectives-list">
                  <ul>
                    <li>Rule of law is upheld, and every citizen receives fair treatment.
                    </li>
                    <li>Constitutional rights are protected and promoted, ensuring freedom and equality for all.

                    </li>
                    <li>Intra and interstate conflicts are minimized, leading to a more harmonious existence.
                      </li>
                  </ul>
                </div>
                
                <h1><span>What We Do
                </span></h1>
                <h2>
                  Education Programs
                </h2>

                  <p className="objectives-list">
                  We offer a variety of programs designed to educate citizens about their constitutional rights and responsibilities. From workshops and seminars to online courses and resources, we provide tools to help everyone understand the importance of the constitution.
                  </p>
                
                <h2>Advocacy Initiatives</h2>
              
              <p className="objectives-list">
              Our advocacy efforts focus on promoting policies and practices that align with constitutional principles. We work with policymakers, legal experts, and community leaders to influence positive change.
              </p>
                
                <h2>
                Community Engagement
                </h2>
                <p className="objectives-list">
                Engaging with communities is at the heart of our initiative. We organize events, discussions, and campaigns that bring people together to discuss and resolve issues through the lens of the constitution.

                </p>
                <h2>
                Research and Analysis
                </h2>
                <p className="objectives-list">
                Our research team conducts in-depth studies on constitutional issues, providing valuable insights and recommendations to support our mission and vision.

                </p>

             
              
                <h1><span> Our Core Values</span></h1>
                <div className="objectives-list">
                  <ul>
                    <li><b>Integrity:</b> Commitment to honesty, transparency, and ethical conduct in all our actions.</li>
                    <li><b>Respect:</b> Upholding the dignity and rights of every individual, as guaranteed by constitutional norms.</li>
                    <li><b>Unity:</b> Fostering a sense of unity through shared values and common goals.</li>
                    <li><b>Empowerment:</b> Educating and empowering citizens to actively participate in democratic processes.</li>
                  </ul>
                </div> 
              </div>
              </div>

            } />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/get_involved" element={<GetInvolved />} />
          </Routes>
        </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

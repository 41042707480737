import React from 'react';
import './ThreeBarIcon.css';
import {Link} from 'react-router-dom';

class Sidebar extends React.Component {
  openNav = () => {
    document.getElementById("mySidebar").style.width = "150px";
    document.getElementById("main").style.marginRight = "150px";
  }

  closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
  }

  render() {
    return (
      <div>
        <div id="mySidebar" className="sidebar">
          {/* <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}>&times;</a> */}
          <button className="closebtn" onClick={this.closeNav}>&times;</button>
          {/* <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/get_involved">Get Involved</a>
          <a href="/contact_us">Contact Us</a>
          <a href="/contact_us">Support Us</a> */}
           <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          {/* <Link to="/visionaries">Visionaries</Link> */}
          <Link to="/">Forth Coming</Link>
          <Link to="/contactus">Contact Us</Link>
          <Link to="/" className="split">Support Us</Link>
        </div>

        <div id="main">
          <button className="openbtn" onClick={this.openNav}>☰ </button>  
         </div>
      </div>
    );
  }
}

export default Sidebar;

import React from "react";
// import logo from '../logos/logo.png'; // Ensure you have the correct path
import logo from '../logos/fulllogo_nobuffer.jpg';
import './FooterNew.css';

import { BsTwitterX } from "react-icons/bs";
import {FaFacebook} from 'react-icons/fa'
import {FaYoutube} from 'react-icons/fa'
import { FaLinkedin } from "react-icons/fa6";
const FooterNew = () => {
    return(
        <div id="footer-container">
            <div id="footer-links-logo">

                <div id="footer-links">
                    <div className="footer-col">
                        <h3>
                            About Us
                        </h3>
                        <ul>
                            <li>
                                <a href="/about">
                                Who we are?
                                </a>
                                
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            Get Involved
                        </h3>
                        <ul>
                            <li>
                            <a href="/get_involved">
                                    Participate
                            </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            
                            Contact Us
                        </h3>

                        <ul>
                            <li>
                            <a href="/contact_us">
                                Get Help
                            </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-col">
                        <h3>
                            Social Media
                        </h3>
                        <br></br>
                        <div id="social-media-icons">
                            <div class="social-icon">
                        <a style={{paddingLeft: '20px'}} href="https://x.com/theconstorder" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={30} color="white" />
                        </a>
                        {/* <br></br> */}
                    </div>

                <div class="social-icon">
                <a  style={{paddingLeft: '20px'}} href="https://x.com/theconstorder" target="_blank" rel="noopener noreferrer">
                    <BsTwitterX size={30} color="white" />
                  </a>
                  {/* <br></br> */}
                </div>

                <div class="social-icon">
                  <a  style={{paddingLeft: '20px'}} href="https://www.youtube.com/@theconstorder" target="_blank" rel="noopener noreferrer">
                    <FaYoutube size={30} color="white" />
                </a>
                </div>


                <div class="social-icon">
                  <a  style={{paddingLeft: '20px'}} href="https://www.linkedin.com/company/theconstorder" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={30} color="white" />
                </a>
                </div>
                </div>
                    </div>
                </div>

                <div id="footer-logo">
                    <a href="/">
                        <img src={logo} alt="Logo" />
                    </a>
                </div>
            </div>

            <div id="copyright">
                &copy; Constitutional Order Institute inc.
            </div>
        </div>
    );
};

export default FooterNew;